import { Company, CompanySchema } from '@models/company';
import { CompanyResponse } from '@talent-engine/api-common';
import { logError } from '@utils/log';

export const normalizeToCompany = (res: CompanyResponse): Company => {
  const company: Company = {
    id: res.id,
    name: res.name,
    location: res.location,
    context: res.context,
    perks: res.perks,
    workday: res.workday,
    workMethodology: res.workMethodology,
    contractLimitations: res.contractLimitations,
    linksOfInterest: res.linksOfInterest,
    medias: res.medias.map((media) => ({
      id: media.id,
      filename: media.filename,
      url: media.url,
      uploadPath: media.uploadPath,
      mediaRole: media.mediaRole
    })),
    holidays: res.holidays
  };

  const result = CompanySchema.safeParse(company);

  if (result.success) {
    return result.data;
  }

  logError({
    context: 'ServiceCompany',
    message: `Zod validation error in Company: ${company.id}`,
    error: result.error
  });

  return company;
};
