import { MediaSchema } from '@models/media';
import { z } from 'zod';
import { LONG_TEXT_MAX_LENGTH, MID_TEXT_MAX_LENGTH } from '../models.constants';

export const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  location: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
  context: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
  perks: z.array(z.number()),
  workday: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
  workMethodology: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
  contractLimitations: z.string().max(LONG_TEXT_MAX_LENGTH).optional(),
  linksOfInterest: z.array(z.string()),
  medias: z.array(MediaSchema),
  holidays: z.string().max(MID_TEXT_MAX_LENGTH).optional()
});

export type Company = z.infer<typeof CompanySchema>;
