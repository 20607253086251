import { Company } from '@models/company';
import {
  CompanyResponse,
  CompanyUpdateRequest
} from '@talent-engine/api-common';
import { ApiServiceOptions, getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';
import { normalizeToCompany } from './service.company.normalizer';

export const ServiceCompany = {
  get: async (opts?: ApiServiceOptions): Promise<Company> => {
    const url = getApiUrl('/company', opts?.useAppProxy);
    const res = await httpClient.get<CompanyResponse>(url, {
      bearer: opts?.token
    });

    return normalizeToCompany(res);
  },

  update: async (
    data: CompanyUpdateRequest,
    opts?: ApiServiceOptions
  ): Promise<Company> => {
    const url = getApiUrl(`/company`, opts?.useAppProxy);
    const res = await httpClient.put<CompanyUpdateRequest, CompanyResponse>(
      url,
      data,
      {
        bearer: opts?.token
      }
    );

    return normalizeToCompany(res);
  },

  uploadImage: async (
    file: Blob,
    type: 'LOGO-DARK' | 'LOGO-LIGHT' | 'COMPANY_PHOTO',
    opts?: ApiServiceOptions
  ): Promise<Company> => {
    const url = getApiUrl(`/company/image`, opts?.useAppProxy);

    const uploadMediaParams = new FormData();

    uploadMediaParams.append('file', file);
    uploadMediaParams.append('mediaRole', type);

    const res = await httpClient.post<FormData, CompanyResponse>(
      url,
      uploadMediaParams,
      {
        bearer: opts?.token
      }
    );

    return normalizeToCompany(res);
  }
};
