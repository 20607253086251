import { PerkFactory } from '@asgard/api-common';
import { faker } from '@faker-js/faker';
import { MediaFactory } from '@models/media';
import { Company } from './model.company';

export class CompanyFactory {
  public static create(props: Partial<Company> = {}): Company {
    const defaultProps: Company = {
      id: faker.datatype.number(),
      name: faker.company.name(),
      location: faker.lorem.paragraph(3),
      context: faker.lorem.paragraph(3),
      perks: PerkFactory.createList(3).map((perk) => perk.id),
      workday: faker.lorem.paragraph(3),
      workMethodology: faker.lorem.paragraph(3),
      contractLimitations: faker.lorem.paragraph(3),
      medias: MediaFactory.createList(2),
      linksOfInterest: [faker.internet.url(), faker.internet.url()],
      holidays: faker.lorem.paragraph(3)
    };

    return { ...defaultProps, ...props };
  }

  public static createList(size = 1): Company[] {
    return Array.from({ length: size }).map(() => {
      return this.create();
    });
  }
}
