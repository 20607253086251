import { useQuery } from '@asgard/ui-common';
import { Company } from '@models/company';
import { HttpError } from '@models/http-error';
import { ServiceCompany } from '@services/company';
import { USE_COMPANY_KEY } from './useCompany.constants';
import { UseCompany } from './useCompany.types';

export const useCompany: UseCompany = () => {
  const {
    data: company,
    isLoading,
    error
  } = useQuery<Company, HttpError>(USE_COMPANY_KEY, async () => {
    return ServiceCompany.get();
  });

  return {
    company,
    isLoading,
    error
  };
};
